import React, { useState, useEffect } from "react";
import "../css/hero-section.css";
import Loader from "../components/loader";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import WhatsappIcon from "./whatsapp-icon";

const HeroSection = () => {
  const navigate = useNavigate();
  

  return (
    <>
      <div className="hero">
        {/* {<WhatsappIcon />} */}
        <Swiper
          modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
          autoplay={{ delay: 2000, disableOnInteraction: true }}
          spaceBetween={30}
          slidesPerView={1}
          style={{ maxWidth: "100%", minWidth: "300px", padding: "0px" }}
          pagination={{ clickable: true }}
          navigation={true}
          // breakpoints={{
          //   320: { navigation: false },
          //   480: { navigation: false },
          //   628: { navigation: false },
          // }}
        >
          {/* Slider 1 */}
          <SwiperSlide>
            <div className="slider-card slider-card1">
              <div className="overlay">
                <h2 className="swipe">Spend Quality Holidays With Us</h2>
                <p className="swipe">
                  Availability is limited! Lock in your reservation now and
                  ensure you don’t miss this exclusive opportunity. Book today!
                </p>
                <button onClick={() => navigate(`/contact`)} className="swipe">
                  Book Now
                </button>
              </div>
            </div>
          </SwiperSlide>
          {/* Slider 2 */}
          <SwiperSlide>
            <div className="slider-card slider-card2">
              <div className="overlay">
                <h2 className="swipe">
                  Explore & Enjoy With Hotel Aroma Nainital
                </h2>
                <p className="swipe">
                  Don't wait! Secure your spot today and embark on a journey
                  you'll never forget. Click the button below to book your
                  experience now!
                </p>
                <button onClick={() => navigate(`/contact`)} className="swipe">
                  Book Now
                </button>
              </div>
            </div>
          </SwiperSlide>
          {/* Slider 3 */}
          <SwiperSlide>
            <div className="slider-card slider-card3">
              <div className="overlay">
                <h2 className="swipe">
                  Enjoy The World Of Relaxation & Tranquility
                </h2>
                <p className="swipe">
                  Your next adventure is just one click away. Book now and get
                  ready to create memories that will last a lifetime!
                </p>
                <button onClick={() => navigate(`/contact`)} className="swipe">
                  Book Now
                </button>
              </div>
            </div>
          </SwiperSlide>
          {/* Slider 4 */}
          <SwiperSlide>
            <div className="slider-card slider-card4">
              <div className="overlay">
                <h2 className="swipe">
                  Book Your Spot Today for Special Discounts!
                </h2>
                <p className="swipe">
                  Start your dream vacation with just a click. Book today and
                  enjoy exclusive offers and personalized service!
                </p>
                <button onClick={() => navigate(`/contact`)} className="swipe">
                  Book Now
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default HeroSection;
